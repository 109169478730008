import('./jquery.min')
import('./bootstrap.min')
import('./slick.min')

// **Partner Slider Secound Started** //

$(document).ready( function () {
  slickFunctions();
});

window.addEventListener("load", () => {
  slickFunctions();
});
// **News Slider Third Ended** //

function slickFunctions() {
  // **Partner Slider Secound Started** //

  $('#slider-partner').slick({
    slidesToShow: 3,
    slidesToScroll: 1,
    centerMode: true,
    focusOnSelect: true,
    arrows: false,
    dots: false,
    autoplay: true,
    autoplaySpeed: 2000,
    responsive: [
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        }
      },
    ]
  });
// **Partner Slider Secound Ended** //

// **News Slider Thrid Started** //
  $('#slider-news').slick({
    slidesToShow: 3,
    slidesToScroll: 1,
    centerMode: true,
    focusOnSelect: true,
    arrows: false,
    dots: false,
    autoplay: true,
    autoplaySpeed: 2500,
    responsive: [
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        }
      },
    ]
  });
  // **News Slider Thrid Ended** //

}

$(".leftNav").on('click', function () {
  if ($(window).width() > 768) {
    var value = $(this).attr('href').substring(1);
    $('.bg-active').removeClass('active');
    $('#' + value).addClass('active');
      $('html, body').animate({
          scrollTop: $('#' + value).offset().top
      }, 1);
  }
});

$(window).bind('hashchange', function (event) {
  var hash = window.location.hash;
  if (!hash) { return; }
  $('a[href="' + hash + '"]').trigger('click');
});
$(window).trigger('hashchange');

$(window).scroll(function () {
  if ($(window).width() > 768) {
    var scrollDistance = $(window).scrollTop() - 450;
    $('.bg-active').each(function (i) {
      if ($(this).position().top <= scrollDistance) {
        $('.section-nav ul li').removeClass('active');
        $('.section-nav ul li').eq(i).addClass('active');
        $('.bg-active').removeClass('active')
        $('.bg-active').eq(i).addClass('active')
      }
    });
  }
}).scroll();

// **NavBar Scrolling Change Color Started** //
$(window).scroll(function () {
  $('nav').toggleClass('scrolled', $(this).scrollTop() > 50);
});
// **NavBar Scrolling Change Color Ended** //
